import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.css'

Vue.use(ElementUI)
// //按需引入element——ui组件库
 import {Checkbox,Slider,DatePicker,Input,TableColumn,Table,Pagination,Tabs,TabPane,Dropdown,DropdownItem,DropdownMenu,Tag,InputNumber,Message,MessageBox,Empty,Loading,Radio,Backtop,Form,FormItem,Steps,Step} from 'element-ui';
// // Vue.component(Button.name, Button);
// Vue.component(Checkbox.name, Checkbox);
// Vue.component(Slider.name, Slider);
// Vue.component(DatePicker.name, DatePicker);
// Vue.component(Input.name, Input);
// Vue.component(TableColumn.name, TableColumn);
// Vue.component(Table.name, Table);
// Vue.component(Pagination.name, Pagination);
// Vue.component(Tabs.name, Tabs);
// Vue.component(TabPane.name, TabPane);
// Vue.component(Dropdown.name, Dropdown);
// Vue.component(DropdownItem.name, DropdownItem);
// Vue.component(DropdownMenu.name, DropdownMenu);
// Vue.component(Tag.name, Tag);
// Vue.component(InputNumber.name, InputNumber);
// Vue.component(Empty.name, Empty);
// Vue.component(Radio.name, Radio);
// Vue.component(Backtop.name, Backtop);
// Vue.component(Form.name, Form);
// Vue.component(FormItem.name, FormItem);
// Vue.component(Steps.name, Steps);
// Vue.component(Step.name, Step);
//表单加载转圈
Vue.directive('loading', {
  bind: function (el, binding) {
    if (binding.value) {
      el.instance = Loading.service({
        target: el
      });
    }
  },
  update: function (el, binding) {
    if (binding.value) {
      if (!el.instance) {
        el.instance = Loading.service({
          target: el
        });
      }
    } else {
      if (el.instance) {
        el.instance.close();
        el.instance = null;
      }
    }
  }
})

//引入二维码生成插件
import VueQr from 'vue-qr'
Vue.use(VueQr)

//引入滑块验证组件
import dragVerify from 'vue-drag-verify2'
Vue.use(dragVerify)

//引入滑块组件
import SlideVerify from "vue-monoplasty-slide-verify";
Vue.use(SlideVerify);




// 引入动画库
import 'animate.css';

// 设置 Message 为所有 Vue 实例的方法
// Vue.prototype.$message = Message;

// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;

// import QRcode  from 'qrcodejs2'

// Vue.component('QRcode', QRcode);



import router from './router/index'
import store from './store/index'


Vue.config.productionTip = false

// 解决toFxied四舍不五入的问题
Number.prototype.toFixed = function(s) {
  // 使用浮点数乘法处理小数
  const factor = Math.pow(10, s);
  // 计算结果值
  const result = Math.round(this * factor) / factor;
  // 将结果转换为字符串并格式化
  let resultString = result.toString();
  // 确保小数点后有足够的位数
  if (resultString.indexOf('.') === -1) {
    resultString += '.';
  }
  while (resultString.split('.')[1].length < s) {
    resultString += '0';
  }
  return resultString;
};

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')



