<template>
    <div class="footer">
        <!-- <h1>底部</h1> -->
        <div class="f-box">
            <!-- 公司信息 -->
            <div class="box-info">
                <div class="info-item">
                    <div class="item-t">客服热线</div>
                    <img class="item-img" src="../assets/image/icon_phone.png" alt="">
                    <div class="item-p">17850832692</div>
                    <div class="item-z">工作时间：9:30~18:30</div>
                </div>

                <div class="info-item">
                    <div class="item-t">公司地址</div>
                    <img class="item-img" src="../assets/image/icon_address.png" alt="">
                    <div class="item-p">福州国家高新区软件园</div>
                    <div class="item-z">福建省-福州市-闽侯</div>
                </div>

                <div class="info-item">
                    <div class="item-z" style="margin-bottom: 20px;">
                        友情链接
                    </div>
                    <div class="item-a"  @click="goTo('purchase')" >
                        产品购买
                    </div>
                  
                    <div class="item-a" @click="goTo('extract')">
                        获取api
                    </div>

                    <div class="item-a" @click="goLj()" >
                        全网最大的免费游戏搬砖论坛-老玖圈
                    </div>
                </div>

                <div class="info-code">
                    <div class="code-t">关于我们</div>
                    <div class="code-box">
                        <div class="code-item">
                            <img class="code-item-img"   src="../assets/image/xhs.png" alt="" style="width: 60px;height: 60px;" >
                            <div class="code-item-title">小红书</div>
                        </div>

                       
                        
                        <div class="code-item" style="margin: 0 40px;">
                            <img class="code-item-img" src="../assets/image/dy.png" alt=""  style="width: 60px;height: 60px;">
                            <div class="code-item-title">抖音</div>
                        </div>

                        <div class="code-item" >
                            <img class="code-item-img"   src="../assets/image/blbl.png" alt="" style="width: 100px;height: 50px;" >
                            <div class="code-item-title">哔哩哔哩</div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 相关声明 -->
            <div class="box-statement">
                <div class="statement-l">
                    <div>
                        <p>免责声明：</p>
                        <p>亿品云仅提供IP服务，用户使用亿品云服务从事的任何行为，均不代表亿品云服务的意志和观点且与亿品云立场无关；</p>
                        <P>严禁用户使用，亿品云服务从事任何违法犯罪行为，由此产生相关责任由当事用户自负，亿品云服务不承担任何法律责任。</P>
                    </div>
                    <p style="margin-top: 34px;">Copyright © 卓鑫网络科技有限公司版权所有</p>
                    <div>

                    </div>
                </div>
                <div class="statement-r">
                    <div>
                        <a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2023021192号-1</a>
                        <p>增值电信业务许可证：闽B2-20240141</p>
                        <p>互联网虚拟专用许可证：B1-20240107</p>
                        <p style="margin-bottom: 12px;">EDI在线数据处理与交易处理业务许可证：闽B2-20240141</p>
                    </div>
                    <div>
                        <img style="height: 20px;float: left;" src="../assets/image/gab-icon.png" alt="" >
                        <a style="margin-left: 5px;"  href="https://beian.mps.gov.cn/#/query/websearch?code=35018302000131">闽公网安备35018302800131</a>
                    </div>
                </div>
            </div>
        </div>

        <Login ref="login"></Login>

    </div>
</template>

<script>
// @ is an alias to /src

import Login from '@/components/Login.vue'
export default {
  name: '',
  components: {
    Login
  },    
  methods:{
    goTo(path){
        this.$router.push(path)
    },
    goLj(){
        window.location.href = 'http://www.laojiuyx.xyz/';
    },
    goPersonal(){
        if(localStorage.getItem('token')){
            this.$router.push('personal')
        }else{
            this.$refs.login.loginBox1=true
        }
    }
  }
}
</script>



<style lang="less" scoped>
.footer {
    width: 100%;
    height: 386px;
    background: #162D4C;
    padding-top: 20px;
    /* // position: fixed;
    // bottom: 0; */
}

.f-box {
    max-width: 1200px;
    min-height: 330px;
    // background: salmon;
    margin: 0 auto;
}

.box-info {
    // background: seagreen;
    padding-bottom: 38px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
}

.info-item {
    width: 260px;
    height: 142px;
    // background: skyblue;
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-right: 20px;

    .item-img {
        width: 26px;
        // background: linear-gradient(90deg, #3650e4 0%, #A5D7D4 32%, #80CCCB 50%, #6687e0 75%, #33B0CB 98%);
        // background: linear-gradient(2deg, #6430F3 0%, #4C6FF0 51%, #FF4EAA 98%);
        border-radius: 16px 16px 16px 16px;
        margin: 20px 0 16px 0;
    }

    .item-t {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.75);
        line-height: 22px;
    }

    .item-p {
        margin-bottom: 6px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 24px;
    }

    .item-z {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;

    }

    .item-a {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        margin-bottom: 12px;
        cursor: pointer;
    }
}

.info-code {
    width: 400px;
    height: 152px;
    // background: slateblue;
    margin-left: 12px;

    .code-box {
        display: flex;
        margin-top: 16px;
        align-items: end;
    }

    .code-t {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.75);
        line-height: 22px;
    }

    .code-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .code-item-img {
        width: 88px;
        height: 88px;
    }

    .code-item-title {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
        margin-top: 9px;
    }
}

.box-statement {

    margin-top: 28px;
    display: flex;


    .statement-r {
        margin-left: 62px;
        // background: salmon;
    }

    a {
        text-decoration: none;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
    }

    p {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
    }
}</style>