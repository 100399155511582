// 引入Vue
import Vue from 'vue'
// 引入Vuex
import Vuex from 'vuex'
// 添加全局引用
Vue.use(Vuex);

// 创建state
const state = {
  // 定义num属性并赋初始值为1
  ids: undefined,
  isBatch:true
}
// Getter，相当于store里面的计算属性
var getters = {
  // AddIds(state) {
  //   return state.ids
  // }
}
// 改变状态，通过commit
var mutations = {
  // // state作为第一个参数
  // ChangeState(state) {
  //   // 自增1
  //   state.ids = 33;
  // },
  ChangeStateWithPara(state, para) {
    // 自增1
      state.ids = para;
  },
  ChangeStateWithPara2(state, para) {
    // 自增1
      state.isBatch = para;
  }

}



// 用来管理mutations
var actions = {
  // ExecChangeState({ commit }) {
  //   // 执行mutations里面定义的ChangeState方法
  //   console.log("actions");
  //   commit('ChangeState');
  // },
  // // 执行异步方法:点击按钮5秒之后再改变属性的值
  // ExecChangeStateAsync({ commit }) {
  //   setTimeout(() => {
  //     commit('ChangeStateWithPara', '我被改变了')
  //   }, 1000);
  // }
}
// 创建store对象
const store = new Vuex.Store({
  // 添加state导出
  state,
  // 导出mutations
  mutations,
  // 导出getter
  getters,
  // 导出actions
  actions
})

// 导出创建的store对象
export default store;