import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component:()=> import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component:()=> import(/* webpackChunkName: "about" */ '../views/Home.vue')
  // },
  { 
    path: '/purchase',
    name: '套餐购买',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Purchase.vue')
  },
  {
    path: '/personal',
    name: '个人中心',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Personal.vue')
  },
  {
    path: '/promotion',
    name: '推广中心',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Promotion.vue')
  },
  {
    path: '/feedback',
    name: '反馈系统',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Feedback.vue')
  },
  {
    path: '/extract',
    name: '提取长效',
    component: () => import(/* webpackChunkName: "about" */ '../views/Extract.vue')
  },
  {
    path: '/service',
    name: '专业技术服务',
    component: () => import(/* webpackChunkName: "about" */ '../views/Service.vue')
  },
  {
    path: '/zz',
    name: 'zz',
    component: () => import(/* webpackChunkName: "about" */ '../views/zz.vue')
  },
 
  
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



// 解决重复点击报错bug
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
}





// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 获取token
  let Token = localStorage.getItem('token');

  // 设置不需要token的页面
  const publicPages = ['首页', '套餐购买','专业技术服务'];

  // 检查即将访问的页面是否在公开页面列表中或token存在
  let isPublicPage = publicPages.includes(to.name);
  let hasToken = Token !== null && Token !== '';

  // 页面标题设置
  // if (to.name) {
  //   document.title = `亿品云-${to.name}`
  // } else {
  //   document.title = `亿品云`
  // }

  // 访问非公开页面且无token时重定向到首页
  if (!isPublicPage && !hasToken) {
    next({
      path: '/',
      query: {
        redirect: to.fullPath // 可选，添加一个重定向的页面路径，登录后可以导航回来
      }
    });
  } else {
    // 继续访问原页面
    next();
  }
});


export default router
