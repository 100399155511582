//引入第一层axios
import http from '@/utils/http.js';
//普通暴露（data为调用函数时需要传入的参数对象）




//账号管理
export const userInfo = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/userInfo',
        data,
    })
}


//充值记录
export const userOrderList = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/userOrderList',
        data,
    })
}

//购买=记录
export const userLongOrder = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/userLongOrder',
        data,
    })
}

//长效管理/已开通长效
export const longManageList = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/list',
        data,
    })
}

// 长效管理/已开通长效/修改备注
export const modifyRemarks = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/longUpdate',
        data,
    })
}

// 长效管理/已开通长效/全部套餐选项列表
export const userPack = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/userPack',
        data,
    })
}

// 长效管理/已开通长效/全部游戏选项列表
export const userGame = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/userGame',
        data,
    })
}



// 长效管理/已开通长效/全部归属地选项列表
export const userCity = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/userCity',
        data,
    })
}

// 长效管理/已开通长效/全部分组选项列表
export const gpList = (data) => {
    return http({
        method: 'post',
        url: '/agent/gp/list',
        data,
    })
}


// 长效管理/已开通长效/批量修改分组
export const batchGp = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/batchGp',
        data,
    })
}


// 长效管理/IP分组管理/新增分组
export const gpAdd = (data) => {
    return http({
        method: 'post',
        url: '/agent/gp/add',
        data,
    })
}

// 长效管理/IP分组管理/删除分组
export const gpDel = (data) => {
    return http({
        method: 'post',
        url: '/agent/gp/del',
        data,
    })
}

// 长效管理/IP分组管理/修改分组
export const gpUpdate = (data) => {
    return http({
        method: 'post',
        url: '/agent/gp/update',
        data,
    })
}

// 长效管理/已开通长效/续费周期列表
export const renewPrice = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/renewPrice',
        data,
    })
}

// 长效管理/已开通长效/IP切换地区列表
export const ipSwitchList = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/ipSwitchList',
        data,
    })
}

// 长效管理/已开通长效/IP切换费用查询
export const ipSwitchPrice = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/ipSwitchPrice',
        data,
    })
}

// 长效管理/已开通长效/批量切换IP
export const ipSwitch = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/ipSwitch',
        data,
    })
}

//全部提取为TXT
export const extracTxt = (params) => {
    return http({
        method: 'get',
        url: '/agent/user/longManage/extracTxt',
        params,
    })
}

//全部套餐接口列表
export const userPackList = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/userPackList',
        data,
    })
}



//套餐转移
export const packShift = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/packShift',
        data,
    })
}


//切换记录
export const ipSwitchLog = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/ipSwitchLog',
        data,
    })
}

// 套餐转移记录
export const packShiftLog = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/packShiftLog',
        data,
    })
}


// 升级宽带
export const upgrade = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/upgrade',
        data,
    })
}


// 升级宽带金额查询
export const upgradePrice = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/upgradePrice',
        data,
    })
}


//升级中继金额查询
export const upRelayPrice = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/upRelayPrice',
        data,
    })
}


//升级中继
export const upRelay = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/upRelay',
        data,
    })
}

//中继入口IP切换费用查询
export const relayipSwitchPrice = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/relayipSwitchPrice',
        data,
    })
}

//中继入口IP切换列表
export const relayipSwitchList = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/relayipSwitchList',
        data,
    })
}

//切换中继
export const relayipSwitch = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/relayipSwitch',
        data,
    })
}

//自动续费列表
export const autoRenewList = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/autoRenewList',
        data,
    })
}

//自动续费-所需金额
export const autoRenewPrice = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/autoRenewPrice',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data),
    })
}


//自动续费-管理
export const autoRenewManage = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/autoRenewManage',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data),
    })
}


//切换游戏列表
export const itemSwitchList = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/itemSwitchList',
        data,
    })
}


//切换游戏信息
export const switchInfo = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/switchInfo',
        data,
    })
}

//切换游戏确定切换
export const itemSwitch = (data) => {
    return http({
        method: 'post',
        url: '/agent/order/itemSwitch',
        data,
    })
}

//切换游戏记录
export const gameSwitchLog = (data) => {
    return http({
        method: 'post',
        url: '/agent/user/longManage/gameSwitchLog',
        data,
    })
}

