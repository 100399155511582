
//axios三层封装第一层，用于增强axios
import axios from "axios";
import router from "../router/index";
//引入element-ui插件   全局的消息提示
import {Message } from 'element-ui'
// 加载动画
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
 
//解码token
import {jwtDecode} from 'jwt-decode';


//1、设置公共服务器地址
// axios.defaults.baseURL = 'http://192.168.2.8:8888';
const http = axios.create({
   
     baseURL: process.env.VUE_APP_BASE_API, //基础URL
    //线上
    //baseURL:'https://zx.laoyuip.com.cn/', //基础URL
   
    timeout:5000,//请求超时时间，
    headers: {
      'Content-Type': 'multipart/form-data'
    }
}) 

 
// 定义开放接口列表
const openApiEndpoints = [
    '/agent/user/forgetPwd',
    '/agent/user/register',
    '/agent/sms/send',
    '/agent/user/login',
    '/agent/open/thali/list', // 添加你的开放接口路径
    // ...可以在这里添加更多的开放接口
  ];

  // 检查URL是否是开放接口
function isOpenApi(url) {
    // 如果URL在开放接口列表中，视为开放接口
    return openApiEndpoints.includes(url);
  }

//2、添加请求拦截器
http.interceptors.request.use(function (config) {
  //// //////////console.log'默认地址是多少 >>>>>>>>>>>>>>', process.env.VUE_APP_BASE_API);
  //// //////////console.log'此接口是开放接口',process.env.VUE_APP_BASE_API);
  // NProgress.start();
   
    
  
    const token = localStorage.getItem('token'); // 假定Token存储在LocalStorage
    // alert('是开放接口')
    if (token) {
      // 如果存在，将Token添加到请求头
      // config.headers['Authorization'] = `Bearer ${token}`;
      //// //////////console.logtoken,'如果存在，将Token添加到请求头');
       // 解码 JWT token，以检查它是否过期
    const decodedToken = jwtDecode(token);
    const isTokenExpired = decodedToken.exp < Date.now() / 1000;
    config.headers['Authorization'] = `Bearer ${token}`;
    // if (!isTokenExpired) {
    //  // //////////console.log'没有过期');
    //   // Token 有效，添加到请求头中
    //   config.headers['Authorization'] = `Bearer ${token}`;
    //   //// //////////console.logtoken, '如果存在，将Token添加到请求头');
    // } else {
    //  // //////////console.log'过期了');
    //   // Token 已过期，重定向到登录页面
    //   localStorage.clear()
    //   if (router.currentRoute.path !== '/') {
       
    //     Message.warning('登录过期,请重新登录!') //登陆失败弹出红色提示框（可选）
    //     // setTimeout(() => {
    //     //    router.push('/');
    //     //    window.location.reload()
    //     // }, 1000);
        
        
    //     return
    //   }
    
    
    //   // window.location.reload()
    //   // return Promise.reject("Token has expired. Redirecting to login page.");
    // }
    } 
    // else {
    //   Message.warning('请先登录!') //登陆失败弹出红色提示框（可选）
    //   //    setTimeout(() => {
    //   //     router.push('/');
    //   //     window.location.reload()
    //   // }, 1000);
    // }
  
  // 如果是开放接口或已添加Token，正常返回配置
    return config;
}, function (error) {
    return Promise.reject(error);
});
 
// 3、添加响应拦截器
//设置响应拦截器
http.interceptors.response.use(function (response) {
    // NProgress.done()
    const res = response
   // //////////console.logres, '响应拦截！！！！！！');
    // if the custom code is not 20000, it is judged as an error.
    // 一切 ok
    // if (res.data.code === 200) { // 这里的 res.code 根据实际接口返回的结构来确定是否正确
    //   return res;
    // } else {
    //   // 其他错误，根据实际情况进行错误处理，这里只输出错误信息
    //  // //////////console.log'接口错误：', res.data.msg || 'Error');
    //   // Message.error(res.data.msg) //登陆失败弹出红色提示框（可选）
    //   return Promise.reject(new Error(res.data.msg || 'Error'));
    // }
    if (res.status !== 200) {
      Message.error(response.data.msg) //登陆失败弹出红色提示框（可选）
    }
    
   return res;

    
}, 
 error => {

 // //////////console.log'err' + error.response.status, '这个错误是什么情况啊！！！！！！！！！') // for debug

  //当等于401重定向到登录
  if ( error.response.status === 401) {
    // TODO: 清除 token，可以根据具体逻辑清除 localStore 或 vuex 里的 token
    localStorage.clear(); // 如果使用 localStorage 存储 token
    // Message.error('啊啊啊啊啊啊') //登陆失败弹出红色提示框（可选）
    setTimeout(() => {
      // 重定向到登录页面
      localStorage.setItem('errMsg',error.response.status)
      window.location.href = '/'; // 假设 '/login' 是您的登录页面的路径
      // router.push({ name: '首页', params: { keyword: error.response.status }});
      // 假设我们想要导航到用户页面，并且传递一个用户ID
      // router.push({ name: 'user', params: { errorMsg: error.response.status }});
      window.location.reload()
    }, 1000)

  }


  return Promise.reject(error)
}
)
 
//将增强后的axios暴露出去
export default http;
