//引入第一层axios
import http from '@/utils/http.js';
//普通暴露（data为调用函数时需要传入的参数对象）


//工单统计
export const workOrderStat = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderStat',
        data
    })
}

//工单列表
export const workOrderList = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderList',
        data
    })
}

//工单创建  
export const workOrderAdd = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderAdd',
        data
    })
}

// 工单详情
export const workOrderDetail = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderDetail',
        data
    })
}



// 工单-客户回复
export const workOrderReply = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderReply',
        data
    })
}


// 工单确认结单
export const workOrderIsOk = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderIsOk',
        data
    })
}


// 工单撤单
export const workOrderRevoke = (data) => {
    return http({
        method: 'post',
        url: '/agent/workOrder/workOrderRevoke',
        data
    })
}