<template>
    <div>
      <div :class="{ 'shake': isShaking }"  style="width: 400px;height: 40px;background: salmon;">
        <input :type="inputType" v-model="localValue" @input="validateInput">
        <span v-if="!isValid && wasValidated">{{ errorMessage }}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      validationRule: {
        type: RegExp,
        required: true
      },
      errorMessage: {
        type: String,
        default: '输入不合法'
      },
      inputType: {
        type: String,
        default: 'text' // default type is text, can be changed to password as needed
      }
    },
    data() {
      return {
        localValue: this.value,
        isShaking: false,
        wasValidated: false,
        isValid: true
      };
    },
    methods: {
      validateInput() {
        this.wasValidated = true;
        this.isValid = this.validationRule.test(this.localValue);
  
        if (!this.isValid) {
          this.triggerShake();
        } else {
          // Reset the shake animation if input becomes valid
          this.isShaking = false;
        }
  
        // Emit an event for the parent component
        this.$emit('input', this.localValue);
      },
      triggerShake() {
        this.isShaking = true;
        this.$el.addEventListener('animationend', () => {
          this.isShaking = false;
        }, { once: true });
      }
    },
    watch: {
      value(val) {
        this.localValue = val;
      }
    }
  }
  </script>